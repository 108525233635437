import { useState, useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';

// Assests
import logo from './assets/logo-st-dark.png';
import './App.css';

// Import Containers
import HomePage from './containers/Home';
import ConverterPage from './containers/Converter';

// Context
import UserContext from './context/UserContext';

function App() {
  // State to manage UserContext
  const [context, setContext] = useState(null);

  // Hooks
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (context) {
      // localStorage.setItem('user', JSON.stringify(user));
      navigate('/converter', { replace: true });
    } else {
      // localStorage.setItem('user', null);
      navigate('/', { replace: true });
    }
  }, [context, location.pathname]);

  return (
    <UserContext.Provider value={{ context, setContext }}>
      <div className='app'>
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/converter' element={<ConverterPage />} />
        </Routes>
      </div>
    </UserContext.Provider>
  );
}

export default App;
