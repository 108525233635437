import React, { useState } from 'react';

// Import data
import data from '../data/puntajes';

const ConvertForm = (props) => {
  // State
  const [puntajes, setPuntajes] = useState({
    matematica: 0,
    lectora: 0,
    historia: 0,
    ciencia: 0,
  });

  // Methods
  const handleChange = (e) => {
    const { name, value } = e.target;
    const newPuntajes = { ...puntajes, [name]: value };
    setPuntajes(newPuntajes);
  };

  // Get puntajes from state
  //   const { matematica, lectora, historia, ciencia } = puntajes;

  // Topics
  const titles = [
    'Competencia Matemática',
    ' CompetenciaLectora',
    'Historia y Ciencias Sociales	',
    'Ciencias',
  ];
  const Topics = ['matematica', 'lectora', 'historia', 'ciencia'].map(
    (topic, ti) => {
      // Get the input value
      const value = puntajes[topic];
      // Get the translated value
      const final = data[topic]?.[value] ? data[topic]?.[value] : '';

      return (
        <div className='col mb-5'>
          <label className='form-label'>{titles[ti]}</label>
          <div className='row row-cols-2'>
            <div className='col'>
              <div className='input-group'>
                <span className='input-group-text' id='basic-addon1'>
                  PSU / PDT
                </span>
                <input
                  className='form-control'
                  name={topic}
                  value={value}
                  required
                  placeholder='Ej: 600'
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className='col'>
              <div className='input-group'>
                <span className='input-group-text' id='basic-addon1'>
                  PAES
                </span>
                <input
                  className='form-control'
                  value={final}
                  required
                  placeholder='Ej: 600'
                  readOnly
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
  );

  return (
    <div>
      <form className='row row-cols-1 text-start mb-5'>
        {/* Topic blocks */}
        {Topics}

        {/* <div className='col-12 mt-2 text-end'>
          <button className='btn btn-lg btn-aqua'>
            Acceder al Convertidor
          </button>
        </div> */}
      </form>
    </div>
  );
};

export default ConvertForm;
