import React from 'react';
import { Link } from 'react-router-dom';

// Assets
import logo from '../assets/logo-st-dark.png';

const Header = () => {
  return (
    <nav className='navbar navbar-expand-lg navbar-light'>
      <div className='container-fluid'>
        <span className='navbar-brand'>
          <img src={logo} className='app-logo' alt='logo' />
        </span>

        <ul className='navbar-nav ms-3 me-auto'>
          <li className='nav-item me-4'>
            <a
              className='nav-link text-uppercase text-black'
              href='https://www.tupuedes.cl/carreras/'
              target='_blank'
              rel='noreferrer'>
              Ver carreras
            </a>
          </li>
          <li className='nav-item me-4'>
            <a
              className='nav-link text-uppercase text-black'
              href='https://www.tupuedes.cl/sedes/'
              target='_blank'
              rel='noreferrer'>
              Sedes
            </a>
          </li>
          <li className='nav-item'>
            <a
              className='nav-link text-uppercase text-black'
              href='https://www.tupuedes.cl/becas-beneficios/'
              target='_blank'
              rel='noreferrer'>
              Becas y financiamiento
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Header;
