import React from 'react';

// Assets
import footerImg from '../assets/sello-footer.png';

// Components
import Header from '../components/Header';
import RegistrationForm from '../components/RegistrationForm';

const Home = (props) => {
  // Props

  // Method

  return (
    <div className='container-fluid'>
      {/* LAYOUT */}
      <div className='row'>
        <div className='d-flex flex-column justify-content-between col-md-7 pt-3'>
          <Header />
          <div className='text-start px-5 mx-5'>
            <h1 className='display-3'>Convertidor de Puntaje</h1>
            <h1 className='display-1 fw-bolder' style={{ color: '#00ad8e' }}>
              PDT a PAES
            </h1>
          </div>

          <div className='p-5'>
            <img src={footerImg} className='mw-100' alt='footer' />
          </div>
        </div>
        <div className='col-md-5 pt-5 bg-light min-vh-100'>
          <div className='px-5'>
            <p className='lead mb-4'>
              Ingresa y accede al convertidor de puntajes
            </p>
            <hr />
            <RegistrationForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
