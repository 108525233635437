const matematica = {
  150: 100,
  164: 131,
  167: 137,
  178: 161,
  181: 167,
  191: 189,
  194: 194,
  203: 214,
  205: 220,
  214: 237,
  216: 243,
  223: 258,
  225: 263,
  226: 263,
  232: 277,
  247: 282,
  263: 295,
  278: 300,
  294: 313,
  303: 318,
  310: 318,
  325: 331,
  340: 336,
  355: 348,
  362: 352,
  369: 352,
  383: 362,
  396: 366,
  409: 375,
  416: 377,
  422: 378,
  434: 386,
  439: 389,
  445: 390,
  456: 399,
  461: 403,
  466: 403,
  476: 414,
  480: 417,
  485: 418,
  493: 430,
  497: 433,
  501: 433,
  509: 444,
  516: 447,
  523: 456,
  526: 458,
  529: 459,
  535: 466,
  537: 467,
  540: 468,
  546: 474,
  548: 475,
  551: 476,
  555: 482,
  557: 483,
  560: 484,
  564: 493,
  566: 494,
  568: 494,
  572: 505,
  574: 507,
  576: 507,
  579: 520,
  581: 521,
  583: 522,
  586: 534,
  588: 536,
  590: 536,
  593: 547,
  596: 548,
  599: 556,
  601: 557,
  602: 558,
  605: 564,
  608: 565,
  611: 571,
  614: 571,
  617: 578,
  619: 578,
  622: 587,
  625: 588,
  627: 599,
  630: 600,
  632: 614,
  635: 614,
  636: 628,
  637: 628,
  640: 629,
  642: 641,
  644: 641,
  646: 651,
  649: 652,
  651: 659,
  653: 660,
  656: 667,
  658: 667,
  660: 675,
  662: 675,
  664: 685,
  667: 685,
  669: 698,
  671: 698,
  674: 713,
  676: 713,
  678: 728,
  681: 728,
  682: 740,
  683: 741,
  685: 741,
  688: 752,
  690: 752,
  692: 762,
  695: 763,
  698: 773,
  700: 774,
  703: 786,
  706: 787,
  708: 801,
  709: 802,
  713: 803,
  716: 819,
  719: 819,
  723: 835,
  726: 836,
  730: 851,
  734: 852,
  738: 867,
  743: 868,
  748: 885,
  753: 885,
  759: 904,
  765: 905,
  772: 926,
  780: 927,
  801: 949,
  824: 973,
  825: 974,
  850: 1000,
};
const lectora = {
  150: 100,
  158: 123,
  161: 133,
  166: 147,
  170: 158,
  174: 169,
  177: 180,
  181: 190,
  185: 202,
  188: 211,
  192: 222,
  195: 231,
  199: 242,
  201: 249,
  205: 259,
  207: 265,
  210: 275,
  211: 279,
  215: 289,
  216: 293,
  220: 305,
  222: 309,
  226: 321,
  235: 326,
  244: 338,
  253: 342,
  262: 353,
  271: 356,
  279: 366,
  288: 368,
  296: 376,
  303: 378,
  311: 386,
  318: 387,
  326: 397,
  333: 398,
  340: 410,
  346: 412,
  353: 425,
  359: 427,
  366: 440,
  372: 443,
  378: 454,
  384: 456,
  390: 466,
  395: 467,
  401: 474,
  407: 475,
  412: 481,
  417: 482,
  423: 489,
  428: 490,
  433: 498,
  438: 500,
  443: 510,
  448: 512,
  453: 524,
  458: 527,
  463: 540,
  468: 543,
  473: 554,
  478: 556,
  483: 565,
  488: 567,
  492: 574,
  497: 575,
  502: 581,
  507: 582,
  511: 588,
  516: 590,
  521: 596,
  526: 599,
  530: 607,
  535: 612,
  540: 622,
  545: 627,
  550: 637,
  554: 642,
  559: 652,
  564: 657,
  569: 664,
  574: 668,
  579: 674,
  584: 678,
  589: 683,
  595: 687,
  600: 692,
  606: 697,
  608: 700,
  611: 703,
  617: 710,
  620: 713,
  623: 717,
  628: 725,
  634: 733,
  640: 742,
  647: 750,
  653: 758,
  660: 765,
  667: 773,
  675: 778,
  682: 786,
  690: 791,
  698: 800,
  707: 806,
  715: 817,
  720: 821,
  725: 823,
  734: 836,
  739: 840,
  745: 841,
  756: 855,
  767: 861,
  779: 875,
  782: 881,
  790: 896,
  793: 901,
  803: 918,
  806: 923,
  817: 943,
  820: 947,
  833: 969,
  835: 974,
  850: 1000,
};
const historia = {
  150: 100,
  163: 130,
  175: 159,
  176: 161,
  186: 185,
  187: 186,
  197: 209,
  206: 231,
  207: 232,
  215: 252,
  216: 254,
  224: 272,
  240: 289,
  255: 290,
  269: 305,
  284: 306,
  290: 320,
  298: 321,
  312: 322,
  325: 338,
  338: 339,
  350: 355,
  362: 356,
  373: 371,
  384: 372,
  395: 385,
  405: 385,
  410: 395,
  415: 396,
  424: 396,
  429: 405,
  433: 406,
  442: 406,
  446: 416,
  450: 417,
  457: 418,
  461: 429,
  465: 431,
  472: 431,
  475: 444,
  479: 446,
  485: 446,
  488: 460,
  491: 461,
  497: 462,
  503: 475,
  508: 476,
  514: 486,
  519: 486,
  524: 494,
  529: 495,
  531: 501,
  533: 502,
  538: 502,
  542: 510,
  547: 510,
  551: 520,
  555: 520,
  559: 532,
  562: 533,
  564: 545,
  566: 547,
  570: 548,
  572: 560,
  574: 562,
  578: 563,
  581: 575,
  585: 576,
  588: 586,
  592: 586,
  594: 593,
  595: 594,
  599: 594,
  601: 599,
  602: 600,
  606: 600,
  609: 607,
  612: 608,
  616: 616,
  619: 617,
  621: 626,
  623: 628,
  626: 629,
  630: 642,
  633: 643,
  637: 657,
  640: 658,
  644: 672,
  647: 672,
  651: 683,
  654: 684,
  658: 692,
  661: 693,
  663: 700,
  665: 700,
  668: 701,
  672: 709,
  676: 710,
  680: 720,
  684: 721,
  688: 733,
  691: 735,
  695: 749,
  699: 750,
  704: 765,
  708: 766,
  712: 779,
  717: 780,
  719: 791,
  721: 792,
  726: 793,
  731: 804,
  737: 805,
  742: 817,
  748: 818,
  754: 833,
  759: 834,
  765: 851,
  771: 852,
  778: 870,
  788: 888,
  789: 889,
  798: 906,
  799: 908,
  809: 926,
  810: 928,
  822: 950,
  835: 973,
  836: 974,
  850: 1000,
};
const ciencia = {
  150: 100,
  159: 126,
  160: 128,
  161: 132,
  162: 135,
  167: 149,
  168: 153,
  170: 159,
  175: 173,
  176: 177,
  179: 184,
  180: 186,
  184: 199,
  185: 202,
  187: 208,
  189: 214,
  191: 219,
  193: 226,
  194: 228,
  195: 232,
  197: 237,
  198: 238,
  199: 244,
  200: 246,
  201: 249,
  203: 254,
  204: 256,
  205: 260,
  206: 264,
  207: 265,
  209: 272,
  210: 273,
  211: 278,
  212: 281,
  214: 287,
  215: 289,
  216: 293,
  217: 295,
  218: 297,
  222: 302,
  227: 303,
  229: 304,
  230: 308,
  232: 309,
  236: 310,
  240: 311,
  244: 315,
  249: 316,
  251: 317,
  252: 320,
  254: 321,
  255: 321,
  260: 321,
  264: 324,
  269: 327,
  274: 327,
  275: 329,
  276: 332,
  280: 332,
  285: 333,
  287: 334,
  292: 338,
  297: 338,
  299: 340,
  303: 343,
  306: 343,
  308: 344,
  309: 345,
  310: 347,
  314: 350,
  319: 350,
  320: 352,
  324: 355,
  328: 356,
  329: 357,
  331: 358,
  332: 361,
  336: 363,
  340: 363,
  342: 366,
  345: 369,
  349: 370,
  350: 371,
  352: 372,
  353: 375,
  356: 377,
  360: 377,
  362: 379,
  365: 382,
  369: 383,
  370: 385,
  371: 385,
  372: 388,
  376: 390,
  380: 390,
  381: 392,
  384: 394,
  388: 395,
  389: 396,
  390: 397,
  391: 398,
  392: 400,
  396: 400,
  399: 402,
  402: 403,
  405: 404,
  406: 405,
  408: 406,
  410: 408,
  413: 408,
  416: 409,
  419: 410,
  421: 412,
  423: 412,
  424: 413,
  425: 414,
  426: 415,
  429: 415,
  432: 416,
  434: 417,
  437: 419,
  438: 419,
  439: 420,
  440: 421,
  441: 422,
  444: 422,
  446: 424,
  449: 424,
  451: 427,
  452: 427,
  453: 429,
  454: 430,
  455: 430,
  458: 431,
  460: 433,
  462: 433,
  464: 435,
  465: 437,
  466: 440,
  467: 441,
  468: 441,
  470: 442,
  474: 444,
  476: 445,
  477: 449,
  478: 451,
  479: 454,
  480: 454,
  482: 455,
  486: 457,
  487: 458,
  488: 462,
  489: 463,
  490: 466,
  491: 467,
  493: 468,
  496: 470,
  498: 470,
  499: 474,
  500: 476,
  501: 478,
  502: 478,
  503: 479,
  506: 480,
  508: 481,
  509: 484,
  510: 486,
  511: 486,
  512: 487,
  513: 487,
  516: 488,
  517: 488,
  518: 491,
  519: 492,
  520: 493,
  522: 493,
  525: 494,
  526: 494,
  527: 496,
  528: 497,
  529: 498,
  531: 498,
  533: 499,
  534: 499,
  535: 501,
  536: 502,
  537: 502,
  538: 503,
  541: 503,
  542: 505,
  543: 506,
  544: 508,
  545: 508,
  546: 509,
  548: 509,
  549: 510,
  550: 513,
  551: 516,
  552: 516,
  553: 517,
  555: 517,
  557: 520,
  558: 526,
  559: 526,
  560: 528,
  562: 528,
  563: 530,
  564: 534,
  565: 538,
  567: 541,
  569: 541,
  570: 544,
  571: 550,
  572: 551,
  573: 553,
  575: 553,
  576: 557,
  577: 561,
  578: 562,
  579: 564,
  581: 564,
  582: 566,
  583: 570,
  584: 571,
  585: 572,
  587: 572,
  588: 574,
  589: 576,
  590: 577,
  591: 578,
  592: 578,
  593: 578,
  594: 580,
  595: 581,
  596: 582,
  597: 582,
  598: 582,
  599: 584,
  600: 585,
  601: 586,
  603: 586,
  604: 587,
  605: 590,
  606: 591,
  608: 591,
  609: 592,
  610: 596,
  611: 598,
  613: 598,
  614: 602,
  615: 604,
  616: 607,
  617: 607,
  618: 610,
  619: 613,
  620: 616,
  621: 619,
  622: 619,
  623: 624,
  624: 627,
  625: 632,
  626: 632,
  627: 632,
  628: 638,
  629: 640,
  630: 644,
  631: 644,
  632: 646,
  633: 649,
  634: 651,
  635: 654,
  636: 654,
  637: 656,
  638: 658,
  639: 661,
  641: 662,
  642: 664,
  643: 666,
  644: 666,
  645: 666,
  646: 668,
  647: 670,
  648: 671,
  649: 671,
  650: 672,
  651: 674,
  652: 676,
  653: 677,
  654: 678,
  655: 680,
  656: 684,
  657: 684,
  658: 686,
  659: 688,
  660: 693,
  662: 694,
  663: 700,
  664: 704,
  665: 706,
  666: 707,
  667: 710,
  668: 716,
  669: 719,
  670: 720,
  671: 720,
  672: 725,
  673: 730,
  674: 731,
  675: 732,
  676: 734,
  677: 738,
  678: 741,
  679: 742,
  680: 742,
  681: 745,
  682: 748,
  683: 750,
  684: 750,
  685: 752,
  686: 754,
  687: 756,
  688: 757,
  689: 758,
  690: 758,
  691: 762,
  692: 764,
  693: 765,
  694: 766,
  696: 768,
  697: 772,
  698: 774,
  699: 777,
  700: 777,
  701: 779,
  702: 784,
  703: 786,
  704: 787,
  705: 790,
  706: 791,
  707: 794,
  709: 800,
  710: 802,
  712: 805,
  713: 806,
  714: 811,
  715: 814,
  716: 814,
  717: 816,
  718: 818,
  720: 820,
  721: 825,
  722: 827,
  724: 827,
  725: 830,
  726: 831,
  727: 832,
  728: 834,
  729: 839,
  731: 839,
  732: 840,
  733: 843,
  734: 843,
  735: 845,
  736: 849,
  737: 851,
  738: 851,
  740: 853,
  741: 855,
  743: 857,
  744: 858,
  745: 862,
  746: 864,
  748: 865,
  749: 867,
  750: 870,
  752: 872,
  754: 873,
  755: 877,
  756: 880,
  757: 881,
  759: 881,
  761: 883,
  762: 886,
  764: 889,
  765: 890,
  766: 895,
  768: 897,
  771: 898,
  774: 900,
  776: 903,
  778: 907,
  780: 908,
  781: 913,
  783: 915,
  784: 916,
  785: 918,
  787: 920,
  792: 926,
  796: 931,
  798: 934,
  800: 936,
  802: 939,
  808: 946,
  812: 951,
  813: 954,
  814: 954,
  816: 956,
  818: 959,
  826: 969,
  829: 974,
  831: 976,
  832: 977,
  833: 979,
  835: 981,
  850: 1000,
};

const data = {
  matematica,
  lectora,
  historia,
  ciencia,
};

export default data;
