import React, { useState, useContext } from 'react';
import { validate as validateRut, format } from 'rut.js';

// Context
import UserContext from '../context/UserContext';

// Components
import 'react-widgets/styles.css';
import { DropdownList } from 'react-widgets';

// Data
import colegios from '../data/colegios.json';
import sedes from '../data/sedes.json';
import carreras from '../data/carreras.json';

const RegistrationForm = (props) => {
  // Props + Logic

  // State
  const [loading, setLoading] = useState(false);
  const [tyc, setTyc] = useState(false);
  const [formData, setFormData] = useState({
    nombre: '',
    apellido_p: '',
    apellido_m: '',
    email: '',
    tipo_id: 'rut',
    rut: '',
    pasaporte: '',
    celular: '',
    colegio: '',
    curso: '',

    anio_prueba: '',
    institucion: '',
    sede: '',
    carrera: '',
  });

  // Hooks
  const { context, setContext } = useContext(UserContext);

  // Methods
  const postUserData = () => {
    // Validations for formData
    if (formData.email === '' || formData.rut === '') return;

    // Post data to server
    fetch('/api/auth/register', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      // Parse to JSON the response
      .then((res) => res.json())
      // If the response is successful ;)
      .then((user) => {
        setLoading(false);
        if (user && user.id) {
          // Set the user in the global UserContext
          setContext(user);
          // Set the user in the localStorage
          // localStorage.setItem('user', JSON.stringify(user));
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    let finalValue = value;
    if (name === 'rut') finalValue = format(value).replace(/\./g, '');
    if (name === 'celular') finalValue = value.replace(/[^0-9]/g, '');

    const newFormData = { ...formData, [name]: finalValue };
    setFormData(newFormData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    postUserData();
  };

  // Logic
  const {
    nombre,
    apellido_p,
    apellido_m,
    tipo_id,
    rut,
    pasaporte,
    email,
    celular,
    colegio,
    curso,

    anio_prueba,
    institucion,
    sede,
    carrera,
  } = formData;

  // Sedes options
  const optionSedes = sedes
    .filter((s) => s.universidadId === Number(institucion))
    .map((s) => <option value={s.id}>{s.sede}</option>);

  // Carreras options
  const optionCarreras = carreras
    .filter(
      (c) =>
        c.universidad_id === Number(institucion) && c.sedeId === Number(sede)
    )
    .map((c) => <option value={c.id}>{c.alias}</option>);

  // Debug
  console.log(formData);

  return (
    <div>
      {context?.email}

      {/* Registration Form */}
      <form className='row row-cols-2 text-start mb-5' onSubmit={handleSubmit}>
        <div className='col-12 mb-3'>
          <label className='form-label'>Nombre</label>
          <input
            className='form-control'
            name='nombre'
            value={nombre}
            required
            placeholder='Nombres'
            onChange={handleChange}
          />
        </div>
        <div className='mb-3'>
          <label className='form-label'>Apellido Paterno</label>
          <input
            className='form-control'
            name='apellido_p'
            value={apellido_p}
            required
            placeholder='Apellido Paterno'
            onChange={handleChange}
          />
        </div>
        <div className='mb-3'>
          <label className='form-label'>Apellido Materno</label>
          <input
            className='form-control'
            name='apellido_m'
            value={apellido_m}
            required
            placeholder='Apellido Paterno'
            onChange={handleChange}
          />
        </div>
        <div className='mb-3'>
          <label className='form-label'>Tipo de Identificación</label>
          <div className='form-check'>
            <input
              className='form-check-input'
              type='radio'
              id='radio_rut'
              name='tipo_id'
              value='rut'
              onChange={handleChange}
              checked={tipo_id === 'rut'}
            />
            <label className='form-check-label' htmlFor='radio_rut'>
              RUT
            </label>
          </div>
          <div className='form-check'>
            <input
              className='form-check-input'
              type='radio'
              id='radio_pasaporte'
              name='tipo_id'
              value='pasaporte'
              onChange={handleChange}
              checked={tipo_id === 'pasaporte'}
            />
            <label className='form-check-label' htmlFor='radio_pasaporte'>
              Pasaporte
            </label>
          </div>
        </div>
        {tipo_id === 'rut' && (
          <div className='mb-3'>
            <label className='form-label'>
              RUT{' '}
              {rut.length > 0 && !validateRut(rut) && (
                <small className='text-danger fw-normal'>No válido.</small>
              )}
            </label>
            <input
              className={`form-control ${
                rut.length > 0 && !validateRut(rut)
                  ? 'border border-danger'
                  : ''
              }`}
              name='rut'
              value={rut}
              required
              placeholder='Ej: 12345678-9'
              onChange={handleChange}
            />
          </div>
        )}
        {tipo_id === 'pasaporte' && (
          <div className='mb-3'>
            <label className='form-label'>Pasaporte</label>
            <input
              className='form-control'
              name='pasaporte'
              value={pasaporte}
              required
              placeholder='Nro de pasaporte'
              onChange={handleChange}
            />
          </div>
        )}
        <div className='mb-3'>
          <label className='form-label'>Email</label>
          <input
            type='email'
            className='form-control'
            name='email'
            value={email}
            required
            placeholder='name@example.com'
            onChange={handleChange}
          />
        </div>
        <div className='mb-3'>
          <label className='form-label'>Celular</label>
          <input
            type='tel'
            className='form-control'
            name='celular'
            value={celular}
            required
            maxlength='9'
            placeholder='9XXXXXXXX'
            onChange={handleChange}
          />
        </div>
        <div className='mb-3'>
          <label className='form-label'>Colegio</label>

          <DropdownList
            id='colegio'
            name='colegio'
            // containerClassName={`is-invaid ${
            //   errors.rbd ? 'border border-danger' : ''
            // }`}
            placeholder='Colegio...'
            data={colegios}
            valueField='id'
            textField='name'
            filter='contains'
            minLength={3}
            onChange={(e) => {
              // console.log(e);
              handleChange({ target: { name: 'colegio', value: e.id } });
              // setColegio(e.id);
              // setValue('rbd', e.id);
            }}
            // onSearch={() => {
            //   setFiltering(true);
            //   setTimeout(() => setFiltering(false), 1000);
            // }}
            // busy={colegio === 0 && filtering}
            messages={{
              emptyList: 'No hay elementos',
              emptyFilter: 'No hay coincidencias.',
            }}
          />

          {/* <input
            className='form-control'
            name='colegio'
            value={colegio}
            required
            placeholder='Colegio...'
            // onChange={handleChange}
          /> */}
        </div>
        <div className='mb-3'>
          <label className='form-label'>Curso</label>
          <select
            className='form-control'
            name='curso'
            value={curso}
            required
            onChange={handleChange}>
            <option value='1'>1ero Medio</option>
            <option value='2'>2do Medio</option>
            <option value='3'>3ero Medio</option>
            <option value='4'>4to Medio</option>
            <option value='5'>Egresado</option>
          </select>
        </div>
        {curso === '5' && (
          <div className='mb-3'>
            <label className='form-label'>Año rendición PSU/PDT</label>
            <input
              className='form-control'
              type='number'
              min='1900'
              max='2022'
              name='anio_prueba'
              value={anio_prueba}
              placeholder='Ej: 2021'
              onChange={handleChange}
            />
          </div>
        )}
        <div className='mb-3'>
          <label className='form-label'>Interés Académico: Institución</label>
          <select
            className='form-control'
            name='institucion'
            value={institucion}
            required
            onChange={handleChange}>
            <option value=''>Selecciona Institución</option>
            <option value='43'>Instituto Profesional Santo Tomás</option>
            <option value='42'>Centro de Formación Técnica Santo Tomás</option>
            <option value='44'>Universidad Santo Tomás</option>
          </select>
        </div>
        <div className='mb-3'>
          <label className='form-label'>Interés Académico: Sede</label>
          <select
            className='form-control'
            name='sede'
            value={sede}
            required
            disabled={optionSedes.length === 0 ? true : false}
            onChange={handleChange}>
            <option value=''>Selecciona Sede</option>
            {optionSedes}
          </select>
        </div>
        <div className='mb-3'>
          <label className='form-label'>Interés Académico: Carrera</label>
          <select
            className='form-control'
            name='carrera'
            value={carrera}
            required
            disabled={optionCarreras.length === 0 ? true : false}
            onChange={handleChange}>
            <option value=''>Selecciona Carrera</option>
            {optionCarreras}
          </select>
        </div>
        <div className='col-12 mt-2 text-end'>
          <div className='form-check text-start'>
            <input
              className='form-check-input'
              type='checkbox'
              value=''
              id='tyc'
              onChange={(e) => setTyc(e.target.checked)}
            />
            <label
              clclassNameass='form-check-label'
              style={{ fontWeight: '400' }}
              for='tyc'>
              He leído y acepto las{' '}
              <a
                href='https://preparate.santotomas.cl/condicionesUso'
                target='_blank'
                rel='noreferrer'>
                condiciones generales de uso de SANTO TOMÁS
              </a>
            </label>
          </div>
          <button
            disabled={loading || !tyc || !validateRut(rut)}
            className='btn btn-lg btn-aqua'>
            {loading ? 'Cargando...' : 'Ingresar'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default RegistrationForm;
